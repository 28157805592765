import React, { useRef, useEffect, createRef } from 'react';
// import { graphql, Link } from 'gatsby';
// import { siteMetadata } from '../../../gatsby-config';
import { SEO } from '../../components/SEO';
import Layout from '../../components/layout';
import { ContactFormSidebar } from '../../components/contactFormSidebar';
import { okBaseUrl } from '../../utils/config.js';
import logo from '../../images/Logo_white@2x.png';
// import { getServiceItemImageName, getKeyFromString } from '../../utils/utils.js';
import loaderImage from '../../images/ajax-loader.gif';

export default props => {
  return (
    <>
      <SEO
        title="Yhteystiedot"
        description="Officekoulutus - kumppanisi koulutusasioissa! Tältä sivultä löydät Officekoulutuksen yhteystiedot."
      />
      <Layout
        // Voisiko nämä kerätä jossain muualla
        // Liikaa yksityiskohtaa tällä abstraktion tasolla
        // ...props tyylisesti
        additionalClassForWrapper="contact-bg"
        headerBgStyles={{
          display: 'flex',
          flexDirection: 'column',
        }}
        headerNavLinkStyle={{ color: 'white' }}
        mainTitle={'Ota yhteyttä tai pyydä tarjous'}
        mainTitleClasses={'mt-5 mb-5'}
        headerTitleStyle={{ color: 'white' }}
        logo={logo}
        {...props}
      >
        <div className="container">
          <div className="row d-flex justify-content-center pt-0 pb-3 pb-md-4">
            <div className="col-lg-6 col-md-6 pt-5 pr-md-6 mb-2">
              <h2>Yhteystiedot</h2>
              <p>
                Officekoulutus Visio
                <br />
                Pasuunatie 4<br />
                00420 Helsinki
                <br />
                puh: 040 840 9799
                <br />
                Y-tunnus: 2111512-2
                <br />
                info@officekoulutus.fi
                <br />
              </p>
              <h2 className="mt-5">Uutiskirje</h2>
              <p>
                Officekoulutus.fi:n uutiskirje Uutiskirjeen tilaajana saat
                linkkejä uusiin verkkosisältöihimme sekä tiedot
                koulutustarjouksista.
              </p>
              <p>
                <a
                  href="http://eepurl.com/cSbTxn"
                  target="_black"
                  className="btn btn-primary my-3 text-uppercase"
                >
                  Tilaa uutiskirje
                </a>
              </p>
              {/* <p className="mt-4">
            Tietosuojaseloste
            <br />
            Sivustollamme käytetään evästeitä. Lue lisätietoja evästeiden
            käytöstä.
          </p> */}
            </div>
            <div className="col-lg-5 col-md-6">
              <div
                className={
                  'shadow shadow-except-mobile pt-3 pt-md-5 px-sm-4 pb-3'
                }
              >
                <ContactFormSidebar
                  title={'Ota yhteyttä tai jätä tarjouspyyntö'}
                  url={`${okBaseUrl}/fi/ota-yhteytta-iframe`}
                />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
